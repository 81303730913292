import { Chip, CircularProgress, Divider, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { useUpdate } from "@refinedev/core";
import { NumericFormatCustom } from "components/inputs/custom-numeric-input";
import { IInvestmentRequest } from "interfaces/investment-requests";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { primary } from "style/color";
import { moneyFormat } from "utils/money-format";

interface ModalProps {
  visible: boolean;
  data: {
    investmentRequest: IInvestmentRequest | undefined;
    value: "PENDING" | "APPROVED" | "REJECTED";
  };
  close: React.Dispatch<React.SetStateAction<boolean>>;
}
export const EvaluateInvestmentRequestModal: React.FC<ModalProps> = ({
  visible,
  close,
  data,
}) => {
  const [appliedAmount, setAppliedAmount] = useState(0);
  const [appliedUnits, setAppliedUnits] = useState(0);
  const [error, setError] = useState(false);
  const [confirmError, setConfirmError] = useState(false);
  const date = new Date().toISOString().substring(0, 10);
  const [executionDate, setExecutionDate] = useState({
    value: date,
    error: false,
  });
  const [rejectionReason, setRejectionReason] = useState("");
  const { mutate, isLoading } = useUpdate();
  const navigate = useNavigate();
  useEffect(() => {
    if (data.investmentRequest) {
      setAppliedAmount(data.investmentRequest.requestedAmount);
      setAppliedUnits(data?.investmentRequest.requestedUnits);
    }
  }, [data.investmentRequest]);
  const submit = () => {

    mutate(
      {
        resource: "investments-request",
        values:
          data.value === "APPROVED"
            ? {
              status: data.value,
              appliedAmount,
              appliedUnits,
              executionDate: executionDate.value,
            }
            : {
              rejectionReason: rejectionReason,
              status: data.value,
            },
        id: `${data.investmentRequest?.investmentRequestId as any
          }/status`,

        errorNotification: (error) => {
          return {
            message: error?.message as string,
            description: "Error",
            type: "error",
          };
        },
      },
      {
        onSuccess: () => {
          setError(false);
          close(false);
          if (data.value === "APPROVED") {
            if (!data.investmentRequest?.grantRequestId) {
              navigate("/investment-requests/status/accepted");
            } else {
              navigate(
                `/grant-requests/${data.investmentRequest?.grantRequestId}`
              );
            }
          } else {
            navigate("/investment-requests/status/rejected");
          }
        },
      }
    );
  };
  return (
    <>
      <Dialog open={confirmError} onClose={() => setConfirmError(false)}>
        <DialogTitle color="primary">
          Confirmation
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Typography>
            The applied amount is 0, are you sure you want to process this Investment
            request?
          </Typography>

        </DialogContent>
        <Divider />
        <DialogActions>
          <>
            <Button
              disabled={isLoading}
              startIcon={
                isLoading && <CircularProgress size={20} color="inherit" />
              }
              variant="contained"
              onClick={() => {
                submit()
              }}
            >
              Confirm
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                setConfirmError(false);
              }}
            >
              Cancel
            </Button>
          </>
        </DialogActions>
      </Dialog>
      <Dialog open={visible} onClose={() => close(false)}>
        <DialogTitle color="primary">
          {data.value === "APPROVED" ? "Approve" : "Reject"} Investment Request
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Typography>
            Are you sure you want to{" "}
            {data.value === "APPROVED" ? "Approve" : "reject"} this Investment
            request?
          </Typography>
          {data.value === "APPROVED" ? (
            <div>
              <br />

              <TextField
                value={moneyFormat(data?.investmentRequest!.requestedAmount, 2.0)}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                label="Requested Amount"
                name="requestedAmount"
                disabled
              />

              <TextField
                value={moneyFormat(appliedAmount, 2, 0)}
                error={error}
                helperText={error && "The applied amount is required"}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                label="Applied Amount"
                name="appliedAmount"
                onChange={(e) => {
                  setAppliedAmount(parseFloat(e.target.value));
                  setError(false);
                }}
                InputProps={{
                  inputComponent: NumericFormatCustom as any,
                }}
              />
              <Typography color={primary}>
                The user is interested in{" "}
                <Chip
                  size="small"
                  label={data?.investmentRequest?.interestedIn}
                />
              </Typography>
              <TextField
                value={
                  data?.investmentRequest!.requestedUnits &&
                  moneyFormat(data?.investmentRequest!.requestedUnits, 4)
                }
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="number"
                label="Requested Units"
                name="requestedUnits"
                disabled
              />

              <TextField
                value={moneyFormat(appliedUnits, 4, 2)}
                error={error}
                helperText={error && "The applied units is required"}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                label="Applied Units"
                name="appliedUnits"
                inputProps={{
                  step: "0.1",
                }}
                InputProps={{
                  inputComponent: NumericFormatCustom as any,
                }}
                onChange={(e) => {
                  setAppliedUnits(parseFloat(e.target.value));
                  setError(false);
                }}
              />
              <TextField
                id="executionDate"
                name="executionDate"
                error={executionDate.error}
                helperText={
                  executionDate.error &&
                  "The date is required, and can not be negative value!"
                }
                defaultValue={executionDate.value}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="date"
                label="Execution Date"
                onChange={(event) => {
                  setExecutionDate({
                    value: event.target.value,
                    error: false,
                  });
                }}
              />
            </div>
          ) : (
            <>
              <TextField
                value={rejectionReason}
                error={error}
                helperText={error && "The rejection reason is required"}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="text"
                label="Rejection reason"
                name="rejectionReason"
                multiline={true}
                rows={3}
                onChange={(e) => setRejectionReason(e.target.value)}
              />
            </>
          )}
        </DialogContent>
        <Divider />
        <DialogActions>
          <>
            <Button
              disabled={isLoading}
              startIcon={
                isLoading && <CircularProgress size={20} color="inherit" />
              }
              variant="contained"
              onClick={() => {
                if (!appliedAmount && data.value === "APPROVED") {
                  setConfirmError(true);
                } else {
                  submit()
                }
              }}
            >
              Confirm
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                close(false);
                setError(false);
              }}
            >
              Cancel
            </Button>
          </>
        </DialogActions>
      </Dialog>
    </>
  );
};
