import React, { useState } from "react";
import { List } from "@refinedev/mui";
import { IResourceComponentsProps } from "@refinedev/core";
import { LogCard } from "components/card/logCard";
import { Tab, Tabs } from "@mui/material";
import CustomTabPanel from "pages/grant-vestments/show";
import { SnapshotCard } from "components/card/snapshotCard";

export const FundBalanceLogList: React.FC<IResourceComponentsProps> = () => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const handleTabChange = (e: any, tabIndex: number) => {
    setCurrentTabIndex(tabIndex);
  };
  return (
    <>
      <List title="Fund Accounts Log">
        <Tabs
          value={currentTabIndex}
          onChange={handleTabChange}
          className="record"
        >
          <Tab label="Balance Change Log" className="record" />
          <Tab label="Daily Snapshot" className="record" />
        </Tabs>
        <CustomTabPanel value={currentTabIndex} index={0}>
          <LogCard></LogCard>
        </CustomTabPanel>
        <CustomTabPanel value={currentTabIndex} index={1}>
          <SnapshotCard></SnapshotCard>
        </CustomTabPanel>
      </List>
    </>
  );
};
