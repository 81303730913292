import { Divider, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { UpdatedValues } from "pages/grant-vestments/update";

interface ModalProps {
  visible: boolean;
  close: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: any;
  onFinish: any;
  UpdatedValues: UpdatedValues;
}
export const UpdateGrantVestmentConfirmationModal: React.FC<ModalProps> = ({
  visible,
  close,
  handleSubmit,
  onFinish,
  UpdatedValues,
}) => {
  return (
    <Dialog open={visible} onClose={() => close(false)}>
      <DialogTitle color="primary">
        GrantVestment Updates confirmation
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Typography>
          Are you sure you want to confirm the following changes?
        </Typography>
        <ul>
          {UpdatedValues.deadline.current?.toString().split("T")[0] !==
            UpdatedValues.deadline.prev?.toString().split("T")[0] && (
            <li>
              <Typography>
                {" "}
                {`Deadline is updated from ${
                  UpdatedValues.deadline?.prev?.toString().split("T")[0]
                } to ${
                  UpdatedValues.deadline?.current?.toString().split("T")[0]
                }`}
              </Typography>
            </li>
          )}
          {UpdatedValues.goalAmount.current !==
            UpdatedValues.goalAmount.prev && (
            <li>
              <Typography>
                {" "}
                {`Goal amount is updated from ${UpdatedValues.goalAmount?.prev} to ${UpdatedValues.goalAmount?.current}`}
              </Typography>
            </li>
          )}
          {UpdatedValues.allOrNothing.current?.toString() !==
            UpdatedValues.allOrNothing.prev?.toString() && (
            <li>
              <Typography>
                {" "}
                {UpdatedValues.allOrNothing.current?.toString() !==
                  UpdatedValues.allOrNothing.prev?.toString() &&
                  `All or Nothing is updated from ${UpdatedValues.allOrNothing?.prev
                    ?.toString()
                    .toUpperCase()} to ${UpdatedValues.allOrNothing?.current
                    ?.toString()
                    .toUpperCase()}`}
                <Typography
                  paragraph
                  variant="smallCopyBold"
                  marginY={0.5}
                  sx={{ color: "neutral.lighter" }}
                >
                  {" "}
                  {UpdatedValues.allOrNothing.current?.toString() !==
                    UpdatedValues.allOrNothing.prev?.toString() &&
                    UpdatedValues.allOrNothing.prev?.toString() === "true" &&
                    `By making it True the users will be refunded and notified in case the GrantVestment close and don't reach the goal.`}
                </Typography>
                <Typography
                  paragraph
                  variant="smallCopyBold"
                  sx={{ color: "neutral.lighter" }}
                >
                  {" "}
                  {UpdatedValues.allOrNothing.current?.toString() !==
                    UpdatedValues.allOrNothing.prev?.toString() &&
                    UpdatedValues.allOrNothing.prev?.toString() === "false" &&
                    `By making it True the users will NOT be refunded or notified in case the GrantVestment close and didn't reach the goal.`}
                </Typography>{" "}
              </Typography>
            </li>
          )}
          {UpdatedValues.status.current?.toString() !==
            UpdatedValues.status.prev?.toString() && (
            <li>
              <Typography>
                {" "}
                {UpdatedValues.status.current?.toString() !==
                  UpdatedValues.status.prev?.toString() &&
                  `Status is updated from ${UpdatedValues.status?.prev} to ${UpdatedValues.status?.current}`}
                <Typography
                  paragraph
                  variant="smallCopyBold"
                  marginY={0.5}
                  sx={{ color: "neutral.lighter" }}
                >
                  {" "}
                  {UpdatedValues.status.current?.toString() !==
                    UpdatedValues.status.prev?.toString() &&
                    UpdatedValues.status.current?.toString() === "CLOSED" &&
                    `By Closing this GrantVestment the users will ${
                      UpdatedValues.successfully
                        ? `be notified`
                        : !UpdatedValues.successfully &&
                          UpdatedValues.allOrNothing?.current?.toString() ===
                            "true"
                        ? `be notified and refunded since it didn't reach goal as all-or-nothing true.`
                        : `not be refunded even though it didn't reach the goal as all-or-nothing was false.`
                    }`}
                  {UpdatedValues.status.current?.toString() !==
                    UpdatedValues.status.prev?.toString() &&
                    UpdatedValues.status.current?.toString() === "RAISING" &&
                    `Updating to raising will make users able to GrantVest and contribute to it.`}
                  {UpdatedValues.status.current?.toString() !==
                    UpdatedValues.status.prev?.toString() &&
                    UpdatedValues.status.current?.toString() === "DRAFT" &&
                    `Updating to draft will hide the GrantVestment, users will not be able to GrantVest and contribute to it.`}
                  {UpdatedValues.status.current?.toString() !==
                    UpdatedValues.status.prev?.toString() &&
                    UpdatedValues.status.current?.toString() === "CANCELED" &&
                    `By Canceling this GrantVestment the users will be refunded`}
                </Typography>
              </Typography>
            </li>
          )}
        </ul>
      </DialogContent>
      <Divider />
      <DialogActions>
        <>
          <Button
            variant="contained"
            onClick={() => {
              handleSubmit(onFinish)();
              close(false);
            }}
          >
            Confirm
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              close(false);
            }}
          >
            Cancel
          </Button>
        </>
      </DialogActions>
    </Dialog>
  );
};
