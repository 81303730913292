import { Create } from "@refinedev/mui";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import MDEditor from "@uiw/react-md-editor";

import {
  IResourceComponentsProps,
  useCreate,
  useCustom,
  useInvalidate,
} from "@refinedev/core";
import { useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CheckIcon from "@mui/icons-material/Check";
import LoadingButton from "@mui/lab/LoadingButton";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { NumericFormatCustom } from "components/inputs/custom-numeric-input";
import rehypeSanitize from "rehype-sanitize";
import React from "react";
import { primary } from "style/color";

export const GrantVestmentCreate: React.FC<IResourceComponentsProps> = () => {
  const { mutate: uploadGrantVestmentImage } = useCreate();
  const invalidate = useInvalidate();
  const { data: categories, isLoading: loadingCategories } = useCustom({
    url: `categories`,
    method: "get",
    config: {
      query: {},
    },
  });
  const { data: tags, isLoading: loadingTags } = useCustom({
    url: `tags`,
    method: "get",
    config: {
      query: {},
    },
  });
  const {
    refineCore: { formLoading, onFinish },
    register,
    handleSubmit,
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
    watch,
    setError,
  } = useForm({
    refineCoreProps: {
      redirect: "show",
      errorNotification: (error): any => {
        return {
          message:
            error?.message && "Oops! something went wrong, please try later.",
          type: "error",
        };
      },
      onMutationSuccess: () => {
        invalidate({
          resource: "grant-vestments",
          invalidates: ["list"],
        });
      },
    },

    warnWhenUnsavedChanges: true,
  });
  interface LinkList {
    title: string;
    url: string;
  }

  const [isUploadLoading, setIsUploadLoading] = useState(false);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [dataRoom, setDataRoom] = useState<LinkList[]>([
    { title: "", url: "" },
  ]);
  const [updates, setUpdates] = useState<{ title: string; url: string }[]>([
    { title: "", url: "" },
  ]);

  const imageInput = watch("image");

  const handleDataRoom = (event: any, index: any) => {
    const { name, value } = event.target;
    let onChangeValue = [...dataRoom];
    // @ts-ignore
    onChangeValue[index][name] = value;
    setDataRoom(onChangeValue);
  };

  const handleUpdates = (event: any, index: any) => {
    const { name, value } = event.target;
    let onChangeValue = [...updates];
    // @ts-ignore
    onChangeValue[index][name] = value;
    setUpdates(onChangeValue);
  };
  const onChangeHandler = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    try {
      setIsUploadLoading(true);
      const target = event.target;
      const file: File = (target.files as FileList)[0];
      clearErrors("imageUrl");
      uploadGrantVestmentImage(
        {
          resource: "grant-vestments/upload",
          values: {
            image: file,
          },
          successNotification: false,
        },
        {
          onSuccess: (data) => {
            setIsUploadLoading(false);

            setValue("imageUrl", data.data);
          },
          onError: () => {
            setError("image", {
              message:
                "Oops!something went wrong with upload! Please try later",
            });
          },
        }
      );
    } catch (error) {
      setError("image", { message: "Upload failed. Please try again." });
      setIsUploadLoading(false);
    }
  };

  return (
    <Create
      isLoading={formLoading}
      saveButtonProps={{
        disabled:
          formLoading || Object.keys(errors).length !== 0 ? true : false,
        onClick: (e: React.BaseSyntheticEvent) => {
          setValue("dataRoom[]", dataRoom);
          setValue("updates[]", updates);
          setValue("tagIds[]", selectedTags);
          const goalAmount = getValues("goalAmount").toString();
          const value = parseFloat(goalAmount.replaceAll(",", ""));
          setValue("goalAmount", value);
          handleSubmit(onFinish, () => false)(e);
        },
      }}
    >
      {loadingCategories || loadingTags ? (
        <Box sx={{ display: "flex", justifyContent: "center", my: 5 }}>
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <Box
          component="form"
          sx={{ display: "flex", flexDirection: "column" }}
          autoComplete="off"
        >
          <InputLabel
            style={{
              fontSize: "12px",
            }}
          >
            Name
          </InputLabel>
          <TextField
            {...register("name", {
              required: "This GrantVestment name is required",
            })}
            error={!!(errors as any)?.name}
            helperText={(errors as any)?.name?.message}
            margin="normal"
            fullWidth
            style={{ marginTop: "1px" }}
            InputLabelProps={{ hidden: true }}
            type="text"
            name="name"
          />
          <InputLabel
            style={{
              fontSize: "12px",
            }}
          >
            Description
          </InputLabel>
          <TextField
            {...register("description", {
              required: "This GrantVestment description section is required",
            })}
            error={!!(errors as any)?.description}
            helperText={(errors as any)?.description?.message}
            margin="normal"
            fullWidth
            InputLabelProps={{ hidden: true }}
            style={{ marginTop: "1px" }}
            type="textarea"
            minRows={3}
            rows={3}
            maxRows={50}
            multiline
            name="description"
          />
          <div data-color-mode="light">
            <InputLabel
              style={{
                fontSize: "12px",
                marginBottom: "3px",
              }}
            >
              About
            </InputLabel>
            <div
              style={{
                border: "2px solid ",
                borderRadius: "5px",
                borderColor: (errors as any)?.about ? "#d3302f" : "transparent",
              }}
            >
              <MDEditor
                {...register("about", {
                  required: "This GrantVestment about is required",
                })}
                value={getValues("about")}
                onChange={(value) => {
                  clearErrors("about");
                  setValue("about", value!);
                }}
                previewOptions={{
                  rehypePlugins: [[rehypeSanitize]],
                }}
                visibleDragbar={false}
                textareaProps={{
                  placeholder: "Please enter about",
                  minLength: 10,
                }}
                commandsFilter={(cmd) =>
                  cmd && /(link|image)/.test(cmd.name as string) ? false : cmd
                }
              />
            </div>
            <InputLabel
              style={{
                fontSize: "12px",
                margin: "3px",
                color: "#d3302f",
                paddingLeft: "10px",
              }}
            >
              {!!(errors as any)?.about && (errors as any)?.about?.message}
            </InputLabel>
          </div>

          <InputLabel
            style={{
              fontSize: "12px",
            }}
          >
            Project By
          </InputLabel>
          <TextField
            {...register("projectBy", {
              required: "This GrantVestment project By is required",
            })}
            error={!!(errors as any)?.projectBy}
            helperText={(errors as any)?.projectBy?.message}
            margin="normal"
            fullWidth
            InputLabelProps={{ hidden: true }}
            style={{ marginTop: "1px" }}
            type="text"
            name="projectBy"
          />
          <InputLabel
            style={{
              fontSize: "12px",
            }}
          >
            Deadline
          </InputLabel>
          <TextField
            {...register("deadline", {
              required: "This GrantVestment deadline is required",
            })}
            error={!!(errors as any)?.deadline}
            helperText={(errors as any)?.deadline?.message}
            margin="normal"
            fullWidth
            InputLabelProps={{ hidden: true }}
            type="date"
            name="deadline"
            style={{ marginTop: "1px" }}
            inputProps={{
              min: new Date().toISOString().substring(0, 10),
            }}
          />
          <InputLabel
            style={{
              fontSize: "12px",
            }}
          >
            Goal Amount
          </InputLabel>
          <TextField
            {...register("goalAmount", {
              required: "This GrantVestment goal amount is required",
            })}
            error={!!(errors as any)?.goalAmount}
            helperText={(errors as any)?.goalAmount?.message}
            margin="normal"
            fullWidth
            InputLabelProps={{ hidden: true }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
              inputComponent: NumericFormatCustom as any,
            }}
            value={getValues("goalAmount") || ""}
            name="goalAmount"
            style={{ marginTop: "1px" }}
          />

          <InputLabel
            style={{
              fontSize: "12px",
            }}
          >
            UpFront Fee
          </InputLabel>
          <TextField
            margin="normal"
            fullWidth
            InputLabelProps={{ hidden: true }}
            InputProps={{
              inputComponent: NumericFormatCustom as any,
            }}
            name="upFrontFee"
            value={0}
            style={{ marginTop: "1px" }}
            disabled
          />
          <InputLabel
            style={{
              fontSize: "12px",
            }}
          >
            UpFront Percentage Fee
          </InputLabel>
          <TextField
            margin="normal"
            fullWidth
            InputLabelProps={{ hidden: true }}
            InputProps={{
              inputComponent: NumericFormatCustom as any,
            }}
            name="upFrontPercentageFee"
            value={0}
            style={{ marginTop: "1px" }}
            disabled
          />

          <InputLabel
            style={{
              fontSize: "12px",
            }}
          >
            Per Annum Fee
          </InputLabel>
          <TextField
            {...register("perAnnumFee", {
              required: "This GrantVestment per annum fee required!",
            })}
            error={!!(errors as any)?.perAnnumFee}
            helperText={(errors as any)?.perAnnumFee?.message}
            margin="normal"
            fullWidth
            InputLabelProps={{ hidden: true }}
            InputProps={{
              inputComponent: NumericFormatCustom as any,
            }}
            value={getValues("perAnnumFee") || ""}
            name="perAnnumFee"
            style={{ marginTop: "1px" }}
          />
          <InputLabel
            style={{
              fontSize: "12px",
            }}
          >
            All Or Nothing{" "}
          </InputLabel>
          <TextField
            {...register("allOrNothing", {
              required: "This GrantVestment all Or Nothing is required",
            })}
            error={!!(errors as any)?.allOrNothing}
            helperText={(errors as any)?.allOrNothing?.message}
            select
            margin="normal"
            fullWidth
            InputLabelProps={{ hidden: true }}
            defaultValue={"true"}
            name="allOrNothing"
            style={{ marginTop: "1px" }}
          >
            {[
              {
                value: "true",
                label: "True",
              },
              {
                value: "false",
                label: "False",
              },
            ].map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <InputLabel
            style={{
              fontSize: "12px",
            }}
          >
            Status
          </InputLabel>
          <TextField
            {...register("status", {
              required: "This GrantVestment status is required",
            })}
            error={!!(errors as any)?.status}
            helperText={(errors as any)?.status?.message}
            select
            margin="normal"
            fullWidth
            InputLabelProps={{ hidden: true }}
            defaultValue={"RAISING"}
            name="status"
            style={{ marginTop: "1px" }}
          >
            {[
              {
                value: "RAISING",
                label: "Raising",
              },
              {
                value: "CLOSED",
                label: "Closed",
              },
              {
                value: "DRAFT",
                label: "Draft",
              },
              {
                value: "CANCELED",
                label: "Canceled",
              },
              {
                value: "EXITED",
                label: "Exited",
              },
            ].map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <InputLabel
            style={{
              fontSize: "12px",
            }}
          >
            Category
          </InputLabel>
          <TextField
            {...register("categoryId")}
            error={!!(errors as any)?.categoryId}
            helperText={(errors as any)?.categoryId?.message}
            select
            margin="normal"
            fullWidth
            InputLabelProps={{ hidden: true }}
            name="categoryId"
            style={{ marginTop: "1px" }}
            defaultValue={
              categories?.data.data.length !== 0
                ? categories?.data.data[0].categoryId
                : ""
            }
          >
            {categories?.data.data.map((option: any, index: number) => (
              <MenuItem key={index} value={option.categoryId}>
                {option.categoryName}
              </MenuItem>
            ))}
          </TextField>
          <Stack direction="row" gap={4} flexWrap="wrap">
            <label htmlFor="image-input">
              <Input
                id="image-input"
                type="file"
                sx={{ display: "none" }}
                onChange={onChangeHandler}
              />
              <input
                id="file"
                {...register("imageUrl", {
                  required: "The GrantVestment image is required",
                })}
                type="hidden"
              />
              <LoadingButton
                loading={isUploadLoading}
                loadingPosition="end"
                endIcon={<FileUploadIcon />}
                variant="contained"
                component="span"
              >
                Upload
              </LoadingButton>
              <br />
              {errors.imageUrl && (
                <Typography variant="caption" color="#d32f2f">
                  {!getValues("imageUrl") &&
                    errors.imageUrl?.message?.toString()}
                </Typography>
              )}
              {imageInput && (
                <Typography variant="caption" color="grey">
                  The image has been uploaded successfully
                </Typography>
              )}
            </label>
          </Stack>
          <InputLabel sx={{ my: 3 }}>
            Optional GrantVestment attributes
          </InputLabel>
          <InputLabel
            style={{
              fontSize: "12px",
            }}
          >
            Tags
          </InputLabel>
          <FormControl>
            <Select
              {...register("tagIds")}
              name="tagIds"
              variant="standard"
              multiple
              value={selectedTags}
              onChange={(e) => setSelectedTags(e.target.value as any)}
              input={<OutlinedInput />}
              renderValue={(selected) => (
                <Stack gap={1} direction="row" flexWrap="wrap">
                  {selected.map((value) => (
                    <Chip
                      color="primary"
                      key={value}
                      label={
                        tags?.data.data.find((e: any) => e.tagId === value)
                          .tagName
                      }
                      onDelete={() => {
                        setSelectedTags(
                          selectedTags.filter((item) => item !== value)
                        );
                      }}
                      deleteIcon={
                        <CancelIcon
                          onMouseDown={(event) => event.stopPropagation()}
                        />
                      }
                    />
                  ))}
                </Stack>
              )}
            >
              {tags?.data.data.map((option: any) => (
                <MenuItem
                  key={option.tagId}
                  value={option.tagId}
                  sx={{ justifyContent: "space-between" }}
                >
                  {option.tagName}
                  {selectedTags.includes(option.tagId) ? (
                    <CheckIcon color="info" />
                  ) : null}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <InputLabel sx={{ mt: 2, color: primary[900] }}>Data room</InputLabel>
          {dataRoom.map((item, index) => (
            <Grid container key={index}>
              <Grid xs={12} sm={12} md={5} paddingRight={{ md: 2 }}>
                <TextField
                  id="dataRoom-title"
                  value={item.title}
                  margin="normal"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  type="text"
                  label="Title"
                  name="title"
                  onChange={(event) => handleDataRoom(event, index)}
                />
              </Grid>
              <Grid xs={12} sm={12} md={5} paddingRight={{ md: 2 }}>
                <TextField
                  id="dataRoom-url"
                  value={item.url}
                  margin="normal"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  type="text"
                  label="URL"
                  name="url"
                  onChange={(event) => handleDataRoom(event, index)}
                />
              </Grid>
              <Grid xs={12} sm={12} md={2} paddingRight={{ md: 2 }}>
                {dataRoom.length > 1 && (
                  <Button
                    sx={{ my: 3 }}
                    onClick={(e) => {
                      e.preventDefault();
                      const newArray = [...dataRoom];
                      newArray.splice(index, 1);
                      setDataRoom(newArray);
                    }}
                  >
                    <CancelIcon />
                  </Button>
                )}
                {index === dataRoom.length - 1 && (
                  <Button
                    sx={{ my: 3 }}
                    onClick={() =>
                      setDataRoom([...dataRoom, { title: "", url: "" }])
                    }
                  >
                    {" "}
                    <AddCircleOutlineIcon />
                  </Button>
                )}
              </Grid>
            </Grid>
          ))}
          <InputLabel sx={{ mt: 2, color: primary[900] }}>Updates</InputLabel>
          {updates.map((item, index) => (
            <Grid container key={index}>
              <Grid xs={12} sm={12} md={5} paddingRight={{ md: 2 }}>
                <TextField
                  id="update-title"
                  value={item.title}
                  margin="normal"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  type="text"
                  label="Title"
                  name="title"
                  onChange={(event) => handleUpdates(event, index)}
                />
              </Grid>
              <Grid xs={12} sm={12} md={5} paddingRight={{ md: 2 }}>
                <TextField
                  id="update-url"
                  value={item.url}
                  margin="normal"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  type="text"
                  label="URL"
                  name="url"
                  onChange={(event) => handleUpdates(event, index)}
                />
              </Grid>
              <Grid xs={12} sm={12} md={2} paddingRight={{ md: 2 }}>
                {updates.length > 1 && (
                  <Button
                    sx={{ my: 3 }}
                    onClick={(e) => {
                      e.preventDefault();
                      const newArray = [...updates];
                      newArray.splice(index, 1);
                      setUpdates(newArray);
                    }}
                  >
                    <CancelIcon />
                  </Button>
                )}
                {index === updates.length - 1 && (
                  <Button
                    sx={{ my: 3 }}
                    onClick={() =>
                      setUpdates([...updates, { title: "", url: "" }])
                    }
                  >
                    <AddCircleOutlineIcon />
                  </Button>
                )}
              </Grid>
            </Grid>
          ))}
        </Box>
      )}
    </Create>
  );
};
