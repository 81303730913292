import { Show } from "@refinedev/mui";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  LinearProgress,
  Link,
  List,
  ListItem,
  listItemClasses,
  ListItemText,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { IResourceComponentsProps, useCustom, useShow } from "@refinedev/core";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import { getPercentage, moneyFormat } from "utils/money-format";
import { longDateFormat, numberOfDays, shortFormat } from "utils/date-format";
import { useEffect, useState } from "react";
import { UpdateMarketValueModal } from "components/modal/update-market-value-modal";
import { neutral, warm } from "style/color";
import MDEditor from "@uiw/react-md-editor";
import { InView } from "react-intersection-observer";
import { IGrantVestmentRequest } from "interfaces/grant-vestment-request";
import PaginatedEntityList from "interfaces/PaginatedEntityList";
import { useParams } from "react-router-dom";

export const GrantVestmentDetails: React.FC<IResourceComponentsProps> = () => {
  const { id } = useParams();
  const { queryResult } = useShow();
  const { data, isLoading, refetch } = queryResult;
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const record = data?.data;
  const funded = getPercentage(record?.raisedAmount, record?.goalAmount);
  const daysLeft =
    numberOfDays(record?.deadline) < 0 ? 0 : numberOfDays(record?.deadline);

  const handleTabChange = (e: any, tabIndex: number) => {
    setCurrentTabIndex(tabIndex);
  };
  const [limit] = useState(5);
  const [offset, setOffset] = useState(0);
  const [grantVestmentRequests, setGrantVestmentRequests] = useState<
    IGrantVestmentRequest[]
  >([]);

  const {
    data: grantVestmentRequestsData,
    isLoading: loadingRequests,
    isSuccess: grantVestmentRequestsSuccess,
  } = useCustom({
    url: `grant-vestments/${id}/requests`,
    method: "get",
    config: {
      query: {
        offset: offset,
        limit: limit,
      },
    },
  });
  useEffect(() => {
    if (grantVestmentRequestsData) {
      if (
        (
          grantVestmentRequestsData.data as PaginatedEntityList<IGrantVestmentRequest>
        )?.pagination?.offset === 0
      ) {
        setGrantVestmentRequests(
          (
            grantVestmentRequestsData.data as PaginatedEntityList<IGrantVestmentRequest>
          ).data
        );
      } else {
        setGrantVestmentRequests([
          ...grantVestmentRequests,
          ...(
            grantVestmentRequestsData.data as PaginatedEntityList<IGrantVestmentRequest>
          ).data,
        ]);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grantVestmentRequestsData]);
  const noData = () => {
    return (
      <Box sx={{ textAlign: "center", my: 9 }}>
        <Typography
          sx={{ color: "neutral.main" }}
          variant="subtitle3"
          gutterBottom
          paragraph
        >
          There’s no data available
        </Typography>
      </Box>
    );
  };

  const fetchData = (inView: boolean) => {
    if (inView && grantVestmentRequestsData?.data?.pagination.nextOffset)
      setOffset(grantVestmentRequestsData?.data?.pagination.nextOffset);
  };
  return (
    <Show title={""} goBack={""} isLoading={isLoading} canEdit>
      {queryResult?.status === "loading" ? (
        <Box sx={{ display: "flex", justifyContent: "center", my: 5 }}>
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <>
          <Box sx={{ m: 1, mt: 0 }}>
            <Grid container>
              <Grid xs={12} sm={12} md={10} paddingRight={{ md: 2 }}>
                <Typography
                  sx={{ color: "neutral.main" }}
                  variant="h3"
                  gutterBottom
                >
                  {record?.name}
                </Typography>
              </Grid>

              {record?.status === "CLOSED" && (
                <Grid
                  xs={12}
                  sm={12}
                  md={2}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    size="large"
                    variant="contained"
                    sx={{
                      m: 2,
                    }}
                    onClick={() => setConfirmationModalOpen(true)}
                  >
                    Market value
                  </Button>
                </Grid>
              )}
            </Grid>
            <Typography variant="bodyCopyRegular" paragraph>
              {record?.description}
            </Typography>
            <Typography
              variant="bodyCopyBold"
              sx={{ color: "neutral.main", mb: 5 }}
              paragraph
            >
              {record?.projectBy}
            </Typography>
            <Chip label={record?.category.categoryName} />

            <Card
              className="single"
              sx={{
                backgroundColor: "transparent",
                mt: 5,
                display: { xs: "block", sm: "block", md: "flex" },
              }}
            >
              <CardMedia
                component="img"
                sx={{
                  width: { xs: "100%", md: "40%" },
                  height: "auto",
                  flex: 2,
                }}
                image={record?.imageUrl}
                alt="Live from space album cover"
              />

              <Box sx={{ ml: 2, width: "100%", flex: 3 }}>
                <CardContent>
                  <>
                    <Typography
                      variant="smallCopy"
                      className="title"
                      sx={{ color: "neutral.lighter" }}
                      paragraph
                    >
                      Raised
                    </Typography>
                    <Typography
                      variant="h3"
                      sx={{ color: "neutral.main" }}
                      gutterBottom
                    >
                      ${moneyFormat(record?.raisedAmount!)}
                      <Typography
                        variant="subtitle3"
                        sx={{
                          float: "right",
                          display: { xs: "none", md: "block" },
                        }}
                      >
                        ${moneyFormat(record?.goalAmount!)} goal
                      </Typography>
                    </Typography>
                    <Box sx={{ width: "100%", my: 2 }}>
                      <LinearProgress
                        color="primary"
                        variant="determinate"
                        value={
                          parseFloat(funded) > 100 ? 100 : parseInt(funded)
                        }
                      />
                    </Box>
                    <Typography
                      variant="smallCopy"
                      color={"primary"}
                      className="title"
                      paragraph
                    >
                      {funded}% funded
                    </Typography>
                  </>

                  <Stack
                    direction="row"
                    alignItems="center"
                    gap={1}
                    sx={{ mb: 1 }}
                  >
                    <AccessTimeIcon />
                    <Typography
                      variant="bodyCopyBold"
                      sx={{ color: "neutral.main" }}
                    >
                      {daysLeft} days to go - until{" "}
                      {shortFormat(record?.deadline)}
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    alignItems="center"
                    gap={1}
                    sx={{ mb: 1 }}
                  >
                    <PeopleOutlineIcon />
                    <Typography
                      variant="bodyCopyBold"
                      sx={{ color: "neutral.main" }}
                    >
                      {record?.grantVestorCounter}
                    </Typography>
                  </Stack>
                  {record?.allOrNothing ? (
                    <Typography
                      variant="smallCopy"
                      sx={{ color: "neutral.lighter" }}
                      paragraph
                    >
                      This project only be funded if it reaches its goal by{" "}
                      {longDateFormat(record?.deadline)}
                    </Typography>
                  ) : (
                    <Typography
                      variant="smallCopy"
                      sx={{ color: "neutral.lighter" }}
                      paragraph
                    >
                      This project can be funded if it doesn't reaches its goal
                      by {longDateFormat(record?.deadline)}
                    </Typography>
                  )}
                  <Typography
                    variant="smallCopy"
                    sx={{ color: "neutral.lighter" }}
                    paragraph
                  >
                    Deadline: {longDateFormat(record?.deadline)}
                  </Typography>
                  <Typography
                    variant="smallCopy"
                    sx={{ color: "neutral.lighter" }}
                    paragraph
                  >
                    Lunched at: {longDateFormat(record?.launchedAt)}
                  </Typography>
                  {record?.status === "CLOSED" && (
                    <>
                      <Typography
                        variant="smallCopy"
                        sx={{ color: "neutral.lighter" }}
                        paragraph
                      >
                        Closed at: {longDateFormat(record?.closedAt)}
                      </Typography>
                      <Typography
                        variant="smallCopy"
                        sx={{ color: "neutral.lighter" }}
                        paragraph
                      >
                        MOIC: {record?.moicValue ? record?.moicValue : 0}
                      </Typography>
                    </>
                  )}
                  <Typography
                    variant="smallCopy"
                    sx={{ color: "neutral.lighter" }}
                    paragraph
                  >
                    Upfront fee: {moneyFormat(record?.upFrontFee)}
                  </Typography>
                  <Typography
                    variant="smallCopy"
                    sx={{ color: "neutral.lighter" }}
                    paragraph
                  >
                    Upfront percentage fee:{" "}
                    {moneyFormat(record?.upFrontPercentageFee)}
                  </Typography>
                  <Typography
                    variant="smallCopy"
                    sx={{ color: "neutral.lighter" }}
                    paragraph
                  >
                    Per annum fee:{" "}
                    {record?.perAnnumFee === -1
                      ? `Standard`
                      : moneyFormat(record?.perAnnumFee)}
                  </Typography>
                </CardContent>
              </Box>
            </Card>
            <Grid container spacing={2} sx={{ mb: 3, mt: 1 }}>
              <Grid item xs={12} sm={6}>
                {record?.tags && record?.tags.length > 0 ? (
                  record?.tags.map((tag: any) => {
                    return (
                      <Chip
                        sx={{ mr: 2 }}
                        key={tag.tagId}
                        label={tag.tagName}
                      />
                    );
                  })
                ) : (
                  <Typography
                    variant="smallCopyBold"
                    sx={{ color: "neutral.lighter", fontWeight: 700 }}
                  >
                    No Tags
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Tabs
              value={currentTabIndex}
              onChange={handleTabChange}
              className="record"
            >
              <Tab label="About" className="record" />
              <Tab label="Data room " className="record" />
              <Tab label="Updates" className="record" />
              <Tab label="Market value history" className="record" />
              <Tab label="GrantVestment requests" className="record" />
            </Tabs>
            <CustomTabPanel value={currentTabIndex} index={0}>
              <div data-color-mode="light">
                {" "}
                <MDEditor.Markdown
                  style={{
                    backgroundColor: "transparent",
                    whiteSpace: "pre-wrap",
                    width: "100%",
                    padding: "5px",
                  }}
                  source={record?.about}
                />
              </div>
            </CustomTabPanel>
            <CustomTabPanel value={currentTabIndex} index={1}>
              {record?.dataRoom && record?.dataRoom.length === 0 ? (
                noData()
              ) : (
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    {record?.dataRoom &&
                      record?.dataRoom
                        .slice(0, Math.ceil(record?.dataRoom.length / 2))
                        .map((item: any, index: number) => {
                          return (
                            <Typography
                              key={index - 1}
                              variant="bodyCopyBold"
                              paragraph
                            >
                              <Link
                                href={item.url}
                                target="_blank"
                                underline="always"
                                color={"inherit"}
                                sx={{
                                  "&:hover": {
                                    cursor: "pointer",
                                  },
                                }}
                              >
                                {item.title}
                              </Link>
                            </Typography>
                          );
                        })}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {record?.dataRoom &&
                      record?.dataRoom
                        .slice(Math.ceil(record?.dataRoom.length / 2))
                        .map((item: any, index: number) => {
                          return (
                            <Typography
                              key={index - 0}
                              variant="bodyCopyBold"
                              paragraph
                            >
                              <Link
                                href={item.url}
                                target="_blank"
                                underline="always"
                                color={"inherit"}
                                sx={{
                                  "&:hover": {
                                    cursor: "pointer",
                                  },
                                }}
                              >
                                {item.title}
                              </Link>
                            </Typography>
                          );
                        })}
                  </Grid>
                </Grid>
              )}
            </CustomTabPanel>
            <CustomTabPanel value={currentTabIndex} index={2}>
              {record?.updates && record?.updates.length === 0 ? (
                noData()
              ) : (
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    {record?.updates &&
                      record?.updates
                        .slice(0, Math.ceil(record?.updates.length / 2))
                        .map((item: any, index: number) => {
                          return (
                            <Typography
                              key={index - 1}
                              variant="bodyCopyBold"
                              paragraph
                            >
                              <Link
                                href={item.url}
                                target="_blank"
                                underline="always"
                                color={"inherit"}
                                sx={{
                                  "&:hover": {
                                    cursor: "pointer",
                                  },
                                }}
                              >
                                {item.title}
                              </Link>
                            </Typography>
                          );
                        })}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {record?.updates &&
                      record?.updates
                        .slice(Math.ceil(record?.updates.length / 2))
                        .map((item: any, index: number) => {
                          return (
                            <Typography
                              key={index - 0}
                              variant="bodyCopyBold"
                              paragraph
                            >
                              <Link
                                href={item.url}
                                target="_blank"
                                underline="always"
                                color={"inherit"}
                                sx={{
                                  "&:hover": {
                                    cursor: "pointer",
                                  },
                                }}
                              >
                                {item.title}
                              </Link>
                            </Typography>
                          );
                        })}
                  </Grid>
                </Grid>
              )}
            </CustomTabPanel>
            <CustomTabPanel value={currentTabIndex} index={3}>
              {record?.marketValueHistories &&
              record?.marketValueHistories.length === 0 ? (
                noData()
              ) : (
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    {record?.marketValueHistories &&
                      record?.marketValueHistories
                        .slice(
                          0,
                          Math.ceil(record?.marketValueHistories.length / 2)
                        )
                        .map((item: any, index: number) => {
                          return (
                            <Card
                              key={`{column-1-${index}}`}
                              sx={{ m: 2, backgroundColor: warm[200] }}
                            >
                              <CardContent sx={{ "&:last-child": { pb: 5 } }}>
                                <Typography
                                  key={index - 1}
                                  variant="bodyCopyBold"
                                  paragraph
                                >
                                  History record ID:{" "}
                                  {item.marketValueId
                                    ? item.marketValueId
                                    : "No Data"}
                                </Typography>
                                <Typography variant="bodyCopyBold" paragraph>
                                  Creation Date:{" "}
                                  {item.createdAt ? item.createdAt : "No Data"}
                                </Typography>
                                <Typography
                                  key={index - 1}
                                  variant="bodyCopyBold"
                                  paragraph
                                >
                                  Value: {item.value ? item.value : "No Data"}
                                </Typography>
                                <Typography variant="bodyCopyBold" paragraph>
                                  MOIC:{" "}
                                  {item.moicValue ? item.moicValue : "No Data"}
                                </Typography>
                              </CardContent>
                            </Card>
                          );
                        })}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {record?.marketValueHistories &&
                      record?.marketValueHistories
                        .slice(
                          Math.ceil(record?.marketValueHistories.length / 2)
                        )
                        .map((item: any, index: number) => {
                          return (
                            <Card
                              key={`{column-2-${index}}`}
                              sx={{ m: 2, backgroundColor: warm[200] }}
                            >
                              <CardContent sx={{ "&:last-child": { pb: 5 } }}>
                                <Typography
                                  key={index - 1}
                                  variant="bodyCopyBold"
                                  paragraph
                                >
                                  History record ID:{" "}
                                  {item.marketValueId
                                    ? item.marketValueId
                                    : "No Data"}
                                </Typography>
                                <Typography variant="bodyCopyBold" paragraph>
                                  Creation Date:{" "}
                                  {item.createdAt ? item.createdAt : "No Data"}
                                </Typography>
                                <Typography
                                  key={index - 1}
                                  variant="bodyCopyBold"
                                  paragraph
                                >
                                  Value: {item.value ? item.value : "No Data"}
                                </Typography>
                                <Typography variant="bodyCopyBold" paragraph>
                                  MOIC:{" "}
                                  {item.moicValue ? item.moicValue : "No Data"}
                                </Typography>
                              </CardContent>
                            </Card>
                          );
                        })}
                  </Grid>
                </Grid>
              )}
            </CustomTabPanel>
            <CustomTabPanel value={currentTabIndex} index={4}>
              <Box sx={{ marginY: 4 }}>
                {grantVestmentRequestsSuccess && (
                  <>
                    {grantVestmentRequestsData?.data?.pagination.hasNext && (
                      <Typography
                        variant="smallCopy"
                        sx={{ color: "neutral.lighter" }}
                        paragraph
                        paddingY={2}
                      >
                        (*) Scroll down to see load more requests
                      </Typography>
                    )}
                    <Grid container rowSpacing={2}>
                      <List
                        className="successor"
                        sx={{
                          width: "100%",
                          bgcolor: "background.paper",

                          [` & .${listItemClasses.root}:hover`]: {
                            backgroundColor: warm[200],
                            cursor: "pointer",
                          },
                        }}
                      >
                        {grantVestmentRequests &&
                          grantVestmentRequests.length > 0 &&
                          grantVestmentRequests.map((request, index) => {
                            return (
                              <>
                                <ListItem key={index}>
                                  <Grid
                                    alignItems="center"
                                    container
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      px: 2,
                                    }}
                                  >
                                    <Grid
                                      item
                                      xs={12}
                                      sm={3}
                                      textAlign={"start"}
                                    >
                                      <ListItemText
                                        sx={{
                                          display: "inline-grid",
                                          width: "auto",
                                          flex: "none",
                                        }}
                                        primary={
                                          <Typography
                                            sx={{ color: `neutral.main` }}
                                            variant="bodyCopyBold"
                                            gutterBottom
                                          >
                                            {`By ${request.user.firstName} ${request.user.lastName}`}
                                          </Typography>
                                        }
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={3}
                                      textAlign={"start"}
                                    >
                                      <ListItemText
                                        sx={{
                                          display: "inline-grid",
                                          width: "auto",
                                          flex: "none",
                                        }}
                                        primary={
                                          <Typography
                                            sx={{ color: `neutral.main` }}
                                            variant="bodyCopyBold"
                                            gutterBottom
                                          >
                                            {`${
                                              //@ts-ignore
                                              request.fund.fundAccountName
                                            }`}
                                          </Typography>
                                        }
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={2}
                                      textAlign={"start"}
                                    >
                                      <Typography
                                        variant="bodyCopyRegular"
                                        sx={{ color: neutral[600] }}
                                      >
                                        {request.status}
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={2}
                                      textAlign={"start"}
                                    >
                                      <Typography
                                        variant="bodyCopyRegular"
                                        sx={{ color: neutral[600] }}
                                      >
                                        {shortFormat(request.createdAt)}
                                      </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={2} textAlign={"end"}>
                                      <Typography
                                        sx={{
                                          px: 1,
                                          py: 1,
                                        }}
                                        variant="bodyCopyBold"
                                        gutterBottom
                                      >
                                        ${moneyFormat(request.appliedAmount)}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </ListItem>

                                <Divider key={index} />
                              </>
                            );
                          })}
                      </List>
                    </Grid>
                  </>
                )}

                {loadingRequests ? (
                  <Grid item xs={12} sm={10} textAlign={"center"} sx={{ m: 3 }}>
                    {" "}
                    <CircularProgress color="primary" />
                  </Grid>
                ) : grantVestmentRequestsData?.data?.pagination.hasNext ? (
                  <InView
                    as="div"
                    onChange={(inView) => fetchData(inView)}
                  ></InView>
                ) : null}
                {!loadingRequests && grantVestmentRequests.length === 0 && (
                  <Typography variant="smallCopyBold">
                    No requests found.
                  </Typography>
                )}
              </Box>
            </CustomTabPanel>
          </Box>
          <UpdateMarketValueModal
            open={confirmationModalOpen}
            setOpen={setConfirmationModalOpen}
            grantvestmentId={record?.grantVestmentId}
            currentValue={
              record?.marketValue ? record?.marketValue : record?.raisedAmount
            }
            raisedAmount={record?.raisedAmount}
            grantvestmentMoic={record?.moicValue}
            refetch={refetch}
          />{" "}
        </>
      )}
    </Show>
  );
};
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
export default function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ m: 2 }}>{children}</Box>}
    </div>
  );
}
