import { HttpError } from "@refinedev/core";
import axios from "axios";
import { fetchAuthSession, signOut } from "aws-amplify/auth";

const axiosInstance = axios.create();
axiosInstance.interceptors.request.use(async (reqConfig) => {
  try {
    const { idToken } =
      (await fetchAuthSession({ forceRefresh: true })).tokens ?? {};
    if (idToken === undefined) {
      signOut();
      window.location.replace("/");
    } else {
      reqConfig.headers = { ...reqConfig.headers };
      reqConfig.headers.Authorization = `Bearer ${
        idToken?.toString() as string
      }`;
    }
    return reqConfig;
  } catch (refreshError) {
    console.log("Refresh token expired. Logging out.", refreshError);
    signOut();
  }

  return reqConfig;
});
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const customError: HttpError = {
      ...error,
      message: error.response?.data?.message,
      statusCode: error.response?.status,
    };

    return Promise.reject(customError);
  }
);

export { axiosInstance };
