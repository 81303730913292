import { Divider } from "@aws-amplify/ui-react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useDelete } from "@refinedev/core";
import { IPlatformAdmin } from "interfaces/platform-admin";
interface UpdateCategoryModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  admin: IPlatformAdmin;
  resource: string;
  refetch: any;
}
export const AdminDeleteConfirmationModal: React.FC<
  UpdateCategoryModalProps
> = ({ setOpen, resource, open, refetch, admin }) => {
  const { mutate: deleteRecord, isLoading } = useDelete();
  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Delete Admin</DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText>
            {`
Are you sure you want to delete the admin with the email "${admin.email}"? This action cannot be undone.`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={isLoading}
            startIcon={
              isLoading && <CircularProgress size={20} color="inherit" />
            }
            onClick={() => {
              deleteRecord(
                {
                  resource: resource,
                  id: admin.sub!,
                  successNotification: () => {
                    return {
                      message: "Admin was deleted successfully",
                      type: "success",
                    };
                  },
                  errorNotification: (error) => {
                    return {
                      message: error?.message as string,
                      description: "Error",
                      type: "error",
                    };
                  },
                },
                {
                  onSuccess: () => {
                    refetch();
                    setOpen(false);
                  },
                }
              );
            }}
          >
            Confirm
          </Button>
          <Button
            onClick={() => {
              setOpen(false);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
