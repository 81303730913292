import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FilledInput,
  FormControl,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Typography,
  listItemClasses,
} from "@mui/material";
import { warm, neutral } from "style/color";
import { shortFormat } from "utils/date-format";
import { useCustom } from "@refinedev/core";
import { IPlatformAdmin } from "interfaces/platform-admin";
import { CreateButton } from "@refinedev/mui";
import { CreateAdmin } from "components/modal/cerate-admin-modal";
import { AdminDeleteConfirmationModal } from "components/modal/admin-delete-confirmation-modal";
import SearchIcon from "@mui/icons-material/Search";

export const AdminList: React.FC = () => {
  const [keyword, setKeyword] = useState<string>("");
  const [limit] = useState(60);
  const {
    data: adminsData,
    isSuccess,
    isFetching: isLoading,
    refetch,
  } = useCustom({
    url: `platform-admin`,
    method: "get",
    config: {
      query: {
        email: keyword,
        limit: limit,
      },
    },

    errorNotification: (error) => {
      return {
        message: error?.message as string,
        description: "Error",
        type: "error",
      };
    },
  });

  const [admins, setAdmins] = useState<IPlatformAdmin[]>([]);
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState({});

  useEffect(() => {
    if (adminsData && adminsData.data.length > 0) {
      setAdmins(adminsData.data as IPlatformAdmin[]);
    }
  }, [adminsData]);

  const clear = () => {
    setAdmins([]);
  };
  const handleWordChange = (event: any) => {
    const { value } = event.target;
    if (event.key === "Enter") {
      clear();
      setKeyword(value);
    }
  };
  return (
    <>
      <Box marginTop={4}>
        {isLoading ? (
          <Grid xs={12} sm={12} textAlign={"center"} sx={{ m: 3 }}>
            {" "}
            <CircularProgress color="primary" />
          </Grid>
        ) : (
          <Grid container xs={12}>
            <Grid
              container
              xs={12}
              marginBottom={2}
              justifyContent={"end"}
              alignContent={"flex-end"}
            >
              <CreateButton onClick={() => setOpen(true)} />
            </Grid>

            <Grid item xs={12} md={4} mb={1}>
              <FormControl variant="outlined" fullWidth>
                <FilledInput
                  className="search"
                  placeholder="Type admin email"
                  size="small"
                  id="keyword"
                  defaultValue={keyword}
                  name="keyword"
                  onChange={(event) =>
                    event?.target.value === "" && setKeyword("")
                  }
                  onKeyDown={(event) => handleWordChange(event)}
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>

            <List
              className="successor"
              sx={{
                mt: 2,
                width: "100%",
                bgcolor: "background.paper",

                [` & .${listItemClasses.root}:hover`]: {
                  backgroundColor: warm[200],
                  cursor: "pointer",
                },
              }}
            >
              {isSuccess &&
                admins &&
                admins.length > 0 &&
                admins.map((admin: IPlatformAdmin, index: number) => {
                  return (
                    <>
                      <ListItem key={`${index}-${admin.sub}`}>
                        <Grid
                          alignItems="center"
                          container
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            px: 2,
                          }}
                        >
                          <Grid item xs={12} sm={3} textAlign={"start"}>
                            <ListItemText
                              sx={{
                                display: "inline-grid",
                                width: "auto",
                                flex: "none",
                              }}
                              primary={
                                <Typography
                                  sx={{ color: `neutral.main` }}
                                  variant="bodyCopyBold"
                                  gutterBottom
                                >
                                  {`${admin.email}`}
                                </Typography>
                              }
                              secondary={
                                <Typography
                                  sx={{ color: `neutral.main` }}
                                  variant="bodyCopyRegular"
                                  gutterBottom
                                >
                                  {`${admin.sub}`}
                                </Typography>
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={2} textAlign={"start"}>
                            <Typography
                              variant="bodyCopyRegular"
                              sx={{
                                color: neutral[900],
                                textTransform: "capitalize",
                              }}
                            >
                              {admin.emailVerified
                                ? "Email verified"
                                : "Email not verified"}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={2} textAlign={"start"}>
                            <Typography
                              variant="bodyCopyRegular"
                              sx={{
                                color: neutral[900],
                                textTransform: "capitalize",
                              }}
                            >
                              {admin.status}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={2} textAlign={"end"}>
                            <Typography
                              variant="bodyCopyRegular"
                              sx={{ color: neutral[600] }}
                            >
                              Joined: {shortFormat(admin.createdDate)}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={2} textAlign={"end"}>
                            {admin.status !== "SUPER_ADMIN" && (
                              <Typography
                                variant="bodyCopyRegular"
                                sx={{ color: neutral[600] }}
                              >
                                <Button
                                  onClick={() => {
                                    setOpenDelete(true);
                                    setSelectedAdmin(admin);
                                  }}
                                >
                                  Delete
                                </Button>
                              </Typography>
                            )}
                          </Grid>
                        </Grid>
                      </ListItem>

                      <Divider key={index} />
                    </>
                  );
                })}
            </List>
          </Grid>
        )}
        {!isLoading && admins.length === 0 && (
          <Typography variant="subtitle3">No pending requests.</Typography>
        )}
        <CreateAdmin open={open} setOpen={setOpen} refetch={refetch} />
        <AdminDeleteConfirmationModal
          setOpen={setOpenDelete}
          open={openDelete}
          resource="platform-admin"
          admin={selectedAdmin as any}
          refetch={refetch}
        />
      </Box>
    </>
  );
};
