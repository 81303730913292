import { TableContainer, Paper, Table, TableBody, TableCell, TableRow, TextField, InputAdornment, Typography, Box, IconButton } from "@mui/material";
import { NumericFormatCustom } from "components/inputs/custom-numeric-input";
import { Tiers } from "interfaces/fund-account";
import { FC, useEffect, useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from "@mui/icons-material/Delete"
interface props {
  tiers: Tiers[]
  title: string;
  reset: boolean;
  changeTiers: (key: string, value: Tiers[]) => void;
}
export const TiersTable: FC<props> = ({ tiers, title, changeTiers, reset }) => {

  const [updatedTiers, setUpdatedTiers] = useState<Tiers[]>(tiers)
  useEffect(() => {
    setUpdatedTiers(tiers)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset])
  const addLine = () => {
    setUpdatedTiers(prev => {
      const newTier = { fee: 0, range: 0, last: false };
      const newTiers = [...prev];
      newTiers.splice(newTiers.length - 1, 0, newTier); // Insert before the last tier
      changeTiers(title.toLocaleLowerCase(), newTiers)
      return newTiers;
    });

  }
  const deleteLine = (index: number) => {
    setUpdatedTiers(prev => {
      const newTiers = prev.filter((_, i) => i !== index);
      changeTiers(title.toLocaleLowerCase(), newTiers)
      return newTiers;
    });

  }
  const handleInputChange = (index: number, field: keyof Tiers, value: any) => {
    setUpdatedTiers(prev => {
      const newTiers = [...prev];
      newTiers[index] = { ...newTiers[index], [field]: value };
      changeTiers(title.toLocaleLowerCase(), newTiers)
      return newTiers;
    });
  };
  const inputProps = (item: any) => item.last ? {} : {
    startAdornment: (
      <InputAdornment position="start">$</InputAdornment>
    ),
    inputComponent: NumericFormatCustom as any,
  };
  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
        <Typography
          variant="body1"
          fontWeight="bold"
          color={"primary"}
        >
          For {title}
        </Typography>
        <IconButton color="primary" onClick={addLine} aria-label="add">
          <AddIcon />
        </IconButton>
      </Box>

      <TableContainer component={Paper} sx={{ boxShadow: 'none', maxWidth: 1150, overflowX: 'auto', border: '1px solid', width: 'fit-content' }}>
        <Table size="small" sx={{ width: 'fit-content', display: 'block' }} >

          <TableBody sx={{ width: 'fit-content', display: 'block' }}>
            {
              updatedTiers.map(
                (item: Tiers, index: number) => (
                  <TableRow key={index}>
                    <TableCell sx={{ zIndex: 1, backgroundColor: 'white', minWidth: 100, border: '1px solid rgba(224, 224, 224, 1);', padding: 0, textAlign: 'center' }}>{(index === 0) ? "1st" : (item.last) ? "Above That" : "Next"}</TableCell>
                    <TableCell sx={{ minWidth: 150, border: '1px solid rgba(224, 224, 224, 1);', padding: 0 }}><TextField
                      defaultValue={
                        (item.last) ? '' : item.range
                      }
                      disabled={item.last}
                      onChange={(e) => handleInputChange(index, 'range', Number(e.target.value))}
                      value={(item.last) ? '' : item.range}
                      margin="normal"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      InputProps={inputProps(item)}
                      name="range"
                      sx={{
                        "& fieldset": { border: 'none' },
                        margin: 0
                      }}
                    /></TableCell>
                    <TableCell sx={{ minWidth: 150, border: '1px solid rgba(224, 224, 224, 1);', padding: 0 }}><TextField
                      defaultValue={
                        item.fee
                      }
                      onChange={(e) => handleInputChange(index, 'fee', Number(e.target.value))}
                      value={item.fee}
                      margin="normal"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                        inputComponent: NumericFormatCustom as any,
                      }}
                      name="range"
                      sx={{
                        "& fieldset": { border: 'none' },
                        margin: 0
                      }}
                    /></TableCell>
                    <TableCell>
                      {
                        (index !== 0 && !item.last) &&
                        <IconButton color="primary" onClick={() => deleteLine(index)} aria-label="add">
                          <DeleteIcon />
                        </IconButton>
                      }
                    </TableCell>
                  </TableRow>


                )
              )}
          </TableBody>
        </Table>
      </TableContainer>

    </>
  );
};
