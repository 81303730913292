import React, { useState } from "react";
import { Show } from "@refinedev/mui";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useShow } from "@refinedev/core";
import { primary } from "style/color";
import { useParams } from "react-router-dom";
import { longDateFormat } from "utils/date-format";
import { IRecommendedCharity } from "interfaces/recommended-charity";
import { EvaluateRecommendedCharityModal } from "components/modal/evaluate-recommended-charity";
export const RecommendedCharitiesDetails: React.FC = () => {
  const { id } = useParams();
  const { queryResult } = useShow<IRecommendedCharity>({
    resource: "recommended-charity",
    id: id,
  });
  const [open, setOpen] = useState(false);
  const [decision, setDecision] = useState<{
    recommendedCharity: IRecommendedCharity | undefined;
    value: "PENDING" | "APPROVED" | "REJECTED";
  }>({ recommendedCharity: undefined, value: "PENDING" });
  const { data } = queryResult;
  const record = data?.data;

  return (
    <Show
      isLoading={queryResult.isLoading}
      title={
        <Typography variant="h5" color={primary}>
          Recommended charity Details
        </Typography>
      }
    >
      {!queryResult.isLoading && (
        <>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <TextField
                value={`${record?.user?.title ? record?.user?.title : ""} ${record?.user?.firstName ? record?.user?.firstName : ""
                  } ${record?.user?.middleName ? record?.user?.middleName : ""} ${record?.user?.lastName ? record.user?.lastName : ""
                  } ${record?.user?.suffix ? record?.user?.suffix : ""}`}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="text"
                label="User "
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                disabled
                value={record?.ein}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="text"
                label="EIN"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                disabled
                value={record?.charityName}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="text"
                label="Charity name"
              />
            </Grid>
            {
              record?.websiteLink &&
              <Grid item xs={12} md={12}>
                <TextField
                  disabled
                  value={record?.websiteLink}
                  margin="normal"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  type="text"
                  label="Website link"
                />
              </Grid>
            }
            <Grid item xs={12} md={6}>
              <TextField
                disabled
                value={record?.contactName}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="text"
                label="Contact name"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                disabled
                value={record?.contactEmail}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="text"
                label="Contact email"
              />
            </Grid>
            {
              record?.contactPhone &&
              <Grid item xs={12} md={12}>
                <TextField
                  disabled
                  value={record?.contactPhone}
                  margin="normal"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  type="text"
                  label="Contact phone"
                />
              </Grid>
            }
            {
              record?.additionalInformation &&
              <Grid item xs={12} md={12}>
                <TextField
                  disabled
                  value={record?.additionalInformation}
                  margin="normal"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  type="text"
                  multiline
                  rows={2}
                  label="Additional Information"
                />
              </Grid>
            }



            <Grid item xs={12} md={6}>
              <TextField
                disabled
                value={longDateFormat(record?.createdAt as Date)}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="text"
                label="CreatedAt"
              />
            </Grid>


            <Grid item xs={12} md={12} textAlign={"end"}>
              {record?.status === "PENDING" && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",

                    justifyContent: "flex-end",
                    marginTop: "20px",
                  }}
                >
                  <EvaluateRecommendedCharityModal
                    visible={open}
                    close={setOpen}
                    data={decision}
                  />

                  <Button
                    size="large"
                    variant="contained"
                    style={{ marginRight: "0.5rem" }}
                    color="primary"
                    onClick={() => {
                      setOpen(true);
                      setDecision({
                        recommendedCharity: record,
                        value: "APPROVED",
                      });
                    }}
                  >
                    Accept
                  </Button>

                </Box>
              )}
            </Grid>
          </Grid>{" "}
        </>
      )}
    </Show>
  );
};
