import { Divider } from "@aws-amplify/ui-react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { useCustom, useUpdate } from "@refinedev/core";
import { FundGroup, IFundAccount } from "interfaces/fund-account";
import PaginatedEntityList from "interfaces/PaginatedEntityList";
import { useEffect, useState } from "react";
import { neutral } from "style/color";
interface AddToGroupModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  fund: IFundAccount;
  refetch: any;
}
export const AddToGroupModal: React.FC<AddToGroupModalProps> = ({
  setOpen,
  open,
  fund,
  refetch,
}) => {
  const [offset, setOffset] = useState(0);
  const [limit] = useState(5);
  const [fundGroupsData, setFundGroupsData] = useState<FundGroup[]>([]);

  const { data: groups, isLoading: isGroupLoading, refetch: refetchGroups,
    isSuccess, } = useCustom<PaginatedEntityList<FundGroup>>({
      url: `fund-group`,
      method: "get",
      config: {
        query: {
          limit: limit,
          offset: offset,
        },

      }
    });
  useEffect(() => {
    if (groups) {
      if (
        (groups.data as PaginatedEntityList<FundGroup>)?.pagination
          ?.offset === 0
      ) {
        setFundGroupsData(
          (groups.data as PaginatedEntityList<FundGroup>).data
        );
      } else {
        setFundGroupsData([
          ...fundGroupsData,
          ...(groups.data as PaginatedEntityList<FundGroup>).data,
        ]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groups]);
  const fetchData = (event: any) => {
    if (!isGroupLoading && groups?.data?.pagination.nextOffset)
      setOffset(groups?.data?.pagination.nextOffset);
  };
  const { mutate } = useUpdate();

  const selectGroup = (group: FundGroup) => {
    console.log(group.fundGroupId, "-----", fund.fundAccountId, fund.group.fundGroupId)
    mutate({
      resource: 'funds',
      values: {},
      id: `${fund.fundAccountId}/group/${group.fundGroupId}`
    }, {
      onSuccess: () => {
        refetchGroups()
        refetch()
        close()
      }
    })
  };
  const close = () => {
    setOpen(false)
  };
  return (
    <>
      <Dialog scroll={'paper'} open={open} onClose={close} fullWidth={true}
        maxWidth={'sm'}>
        <DialogTitle>Add Fund  to Group</DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText>

          </DialogContentText>

          <>
            <Grid container rowSpacing={2}>
              <Grid xs={12} item>
                <List
                  className="successor"
                  sx={{ width: "100%", bgcolor: "background.paper" }}
                >
                  {isSuccess && fundGroupsData && fundGroupsData.length > 0 && (
                    fundGroupsData.filter(item => item.fundGroupId !== fund.group.fundGroupId).map((group: FundGroup, index: number) => (
                      <>
                        {" "}
                        <ListItem
                          className="successor-item"
                          sx={{ display: "-webkit-inline-box" }}
                          key={index}
                        >
                          <Grid alignItems="center" container>
                            <Grid
                              item
                              xs={12}
                              sm={9}
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <ListItemText
                                sx={{ width: "auto", flex: "none" }}
                                primary={
                                  <Typography
                                    sx={{ color: neutral[900] }}
                                    variant="bodyCopyRegular"
                                    gutterBottom
                                  >
                                    {group.fundGroupId}: {group.funds.map(item => item.fundAccountName).toString()}
                                  </Typography>
                                }
                              />

                            </Grid>
                            <Grid item xs={12} sm={3} textAlign={"right"}>
                              <Button onClick={() => selectGroup(group)}>Select</Button>
                            </Grid>
                          </Grid>
                        </ListItem>
                        {index !== fundGroupsData.length - 1 && <Divider key={index + 100} />}
                      </>

                    ))
                  )}
                </List>
              </Grid>
              <Grid item xs={12} textAlign={'center'}>
                {isGroupLoading ? (
                  <CircularProgress color="primary" />
                ) : groups?.data?.pagination.hasNext ? (
                  <Button onClick={fetchData} onMouseDown={(event) => event.stopPropagation()}>Load More</Button>
                ) : null}
              </Grid>
            </Grid>
          </>
        </DialogContent>
        <DialogActions>

          <Box>
            <Button
              onClick={close}
            >
              Cancel
            </Button>
          </Box>

        </DialogActions>
      </Dialog >
    </>
  );
};

