import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { IResourceComponentsProps, useCustom } from "@refinedev/core";
import React, { useState } from "react";
import CustomTabPanel from "pages/grant-vestments/show";
import { NetaInfoForm } from "components/form/netaInfoForm";
import { AdminList } from "components/list/admins-list";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { IPlatformAdmin } from "interfaces/platform-admin";
export const NetaSetting: React.FC<IResourceComponentsProps> = () => {
  const { data, isLoading } = useCustom({
    url: `platform-admin/super-admin`,
    method: "get",
  });
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const handleTabChange = (e: any, tabIndex: number) => {
    setCurrentTabIndex(tabIndex);
  };
  const { user } = useAuthenticator();
  const superAdmin = data?.data[0] as IPlatformAdmin;

  return isLoading ? (
    <Box sx={{ display: "flex", justifyContent: "center", my: 5 }}>
      <CircularProgress color="primary" />
    </Box>
  ) : (
    <>
      <Card sx={{ boxShadow: "none" }}>
        <CardContent>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{ color: `neutral.main`, p: 1 }}
              variant="subtitle1"
            >
              Settings
            </Typography>
          </Box>
          {user.userId === superAdmin.sub ? (
            <>
              <Tabs
                value={currentTabIndex}
                onChange={handleTabChange}
                className="record"
              >
                <Tab label="Neta Information" className="record" />
                <Tab label="Admins " className="record" />
              </Tabs>
              <CustomTabPanel value={currentTabIndex} index={0}>
                <NetaInfoForm />
              </CustomTabPanel>
              <CustomTabPanel value={currentTabIndex} index={1}>
                <AdminList />
              </CustomTabPanel>
            </>
          ) : (
            <Typography
              sx={{ color: `neutral.main`, p: 1 }}
              variant="bodyCopyRegular"
            >
              {" "}
              Only super admin is able to manage Neta settings and admins.
            </Typography>
          )}
        </CardContent>
      </Card>
    </>
  );
};
