import React, { useState } from "react";
import * as yup from "yup";
import { useCustom, useUpdate } from "@refinedev/core";
import { useNavigate, useParams } from "react-router-dom";
import { EditButton, SaveButton, Show } from "@refinedev/mui";
import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  TextField,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useFormik } from "formik";
import { User } from "providers/rest-data-provider/utils/interfaces";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";

const validationSchema = yup.object({
  firstName: yup.string().required("firstName is required"),
  lastName: yup.string().required("lastName is required"),
  city: yup.string().required("city is required"),
  state: yup.string().required("state is required"),
  addressLine1: yup.string().required("addressLine1 is required"),
  zip: yup.number().required("zip is a required number"),
  phoneNumber: yup.string().required("phoneNumber is required"),
});
export const UserDetails: React.FC = () => {
  const { sub } = useParams();
  const navigate = useNavigate();
  const [updateForm, setUpdateForm] = useState<boolean>(false);
  const { data, isLoading } = useCustom({
    url: `users/sub`,
    method: "get",
    config: {
      query: {
        sub: sub,
      },
    },
  });

  const { mutate } = useUpdate();
  const formik = useFormik({
    initialValues: {
      userId: data?.data.id ?? "",
      sub: data?.data.sub ?? "",
      title: data?.data?.title ?? "",
      suffix: data?.data.suffix ?? "",
      middleName: data?.data.middleName ?? "",
      email: data?.data.email ?? "",
      firstName: data?.data?.firstName ?? "",
      lastName: data?.data.lastName ?? "",
      city: data?.data.city ?? "",
      state: data?.data.state ?? "",
      addressLine1: data?.data.addressLine1 ?? "",
      addressLine2: data?.data.addressLine2 ?? "",
      dateOfBirth: (dayjs(data?.data?.dateOfBirth) as any) ?? null,
      zip: data?.data.zip ?? "",
      reference: data?.data.reference ?? "",
      phoneNumber: data?.data.phoneNumber ?? "",
      phoneNumberIsMobile: data?.data.phoneNumberIsMobile ?? false,
      alternativePhoneNumberIsMobile:
        data?.data.alternativePhoneNumberIsMobile ?? false,
      alternativePhoneNumber: data?.data.alternativePhoneNumber ?? "",
      alternativeEmail: data?.data.alternativeEmail ?? "",
      status: data?.data.status ?? "",
      createdAt: data?.data.createdAt ?? "",
    } as User,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      mutate({
        resource: "users",
        id: data?.data.sub,
        values: {
          title: values.title,
          suffix: values.suffix,
          middleName: values.middleName,
          email: values.email,
          firstName: values?.firstName,
          lastName: values.lastName,
          city: values.city,
          state: values.state,
          addressLine1: values.addressLine1,
          addressLine2: values.addressLine2,
          dateOfBirth: values.dateOfBirth,
          zip: values.zip,
          reference: values.reference,
          phoneNumber: values.phoneNumber,
          phoneNumberIsMobile: values.phoneNumberIsMobile,
          alternativePhoneNumberIsMobile: values.alternativePhoneNumberIsMobile,
          alternativePhoneNumber: values.alternativePhoneNumber,
          alternativeEmail: values.alternativeEmail,
        },
      });
      setUpdateForm(false);
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Show
          isLoading={isLoading}
          title="User details"
          goBack={
            <IconButton onClick={() => navigate(-1)}>
              <ArrowBackIcon />
            </IconButton>
          }
          headerButtons={
            <>
              {!updateForm ? (
                <EditButton
                  onClick={() => {
                    setUpdateForm(true);
                  }}
                />
              ) : (
                <SaveButton disabled={!formik.isValid} type="submit" />
              )}
            </>
          }
        >
          {!isLoading && data ? (
            <Box
              component="form"
              sx={{ display: "flex", flexDirection: "column" }}
              autoComplete="off"
            >
              {!updateForm ? (
                <TextField
                  value={`${formik.values?.title ? formik.values?.title : ""} ${
                    formik.values?.firstName ? formik.values?.firstName : ""
                  } ${
                    formik.values?.middleName ? formik.values?.middleName : ""
                  }${formik.values?.lastName ? formik.values?.lastName : ""} ${
                    formik.values?.suffix ? formik.values?.suffix : ""
                  }`}
                  margin="normal"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  type="text"
                  label="Name "
                  name="title"
                  disabled={!updateForm}
                />
              ) : (
                <div>
                  <Grid container>
                    <Grid xs={12} sm={1} md={1} paddingRight={{ md: 2 }}>
                      <FormControl fullWidth margin="normal">
                        <InputLabel>Title</InputLabel>
                        <Select
                          id="demo-simple-select"
                          value={formik.values?.title && formik.values?.title}
                          label="title"
                          onChange={(e) =>
                            formik.setFieldValue("title", e.target.value)
                          }
                          onBlur={formik.handleBlur}
                        >
                          <MenuItem value={"Mr"}>Mr</MenuItem>
                          <MenuItem value={"Miss"}>Ms</MenuItem>
                          <MenuItem value={"Miss"}>Miss</MenuItem>
                          <MenuItem value={"Mrs"}>Mrs</MenuItem>
                          <MenuItem value={"Mx"}>Mx</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid xs={12} sm={12} md={3} paddingRight={{ md: 2 }}>
                      <TextField
                        defaultValue={formik.values?.firstName}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        type="text"
                        label="First Name "
                        name="firstName"
                        disabled={!updateForm}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.firstName &&
                          Boolean(formik.errors.firstName)
                        }
                      />
                    </Grid>
                    <Grid xs={12} sm={12} md={3} paddingRight={{ md: 2 }}>
                      <TextField
                        defaultValue={
                          formik.values?.middleName && formik.values?.middleName
                        }
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        type="text"
                        label="Middle Name "
                        name="middleName"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </Grid>
                    <Grid xs={12} sm={12} md={3} paddingRight={{ md: 2 }}>
                      <TextField
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        type="text"
                        label="Last Name "
                        name="lastName"
                        disabled={!updateForm}
                        value={formik.values.lastName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.lastName &&
                          Boolean(formik.errors.lastName)
                        }
                      />
                    </Grid>
                    <Grid xs={12} sm={12} md={2} flexGrow={2}>
                      <TextField
                        defaultValue={
                          formik.values?.suffix && formik.values?.suffix
                        }
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        type="text"
                        label="Suffix"
                        name="suffix"
                        disabled={!updateForm}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </Grid>
                  </Grid>
                </div>
              )}

              <TextField
                fullWidth
                id="email"
                name="email"
                label="Email"
                margin="normal"
                InputLabelProps={{ shrink: true }}
                defaultValue={formik.values.email}
                value={formik.values.email}
                type="text"
                disabled
              />

              {formik.values.dateOfBirth && (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <FormControl fullWidth sx={{ py: 1.5 }}>
                    <DatePicker
                      slotProps={{
                        textField: {
                          id: "datePicker",
                          name: "dateOfBirth",
                          required: true,
                        },
                      }}
                      label="Date of birth"
                      disableFuture={true}
                      format="MM/DD/YYYY"
                      name="dateOfBirth"
                      value={formik.values.dateOfBirth}
                      disabled={!updateForm}
                      onChange={(date) =>
                        formik.setFieldValue(
                          "dateOfBirth",
                          date && new Date(date).toLocaleDateString("en-us")
                        )
                      }
                    />
                  </FormControl>
                </LocalizationProvider>
              )}
              {!updateForm ? (
                <>
                  <TextField
                    value={`${
                      formik.values?.addressLine1 &&
                      `${formik.values?.addressLine1}`
                    }${formik.values?.city && `, ${formik.values?.city}`}${
                      formik.values?.state && `, ${formik.values?.state}`
                    } ${formik.values?.zip && `, ${formik.values?.zip}`} `}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    label="Address "
                    name="addressLine1"
                    disabled={!updateForm}
                  />
                  {formik.values?.addressLine2 && (
                    <TextField
                      value={formik.values?.addressLine2}
                      margin="normal"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      label="Second Address "
                      name="addressLine2"
                      disabled={!updateForm}
                    />
                  )}
                </>
              ) : (
                <div>
                  <Grid container>
                    <Grid xs={12} sm={12} md={3} paddingRight={{ md: 2 }}>
                      <TextField
                        value={formik.values.addressLine1}
                        margin="normal"
                        required
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        type="text"
                        label="Address line 1 "
                        name="addressLine1"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.addressLine1 &&
                          Boolean(formik.errors.addressLine1)
                        }
                      />
                    </Grid>
                    <Grid xs={12} sm={12} md={3} paddingRight={{ md: 2 }}>
                      <TextField
                        value={
                          formik.values?.addressLine2
                            ? formik.values?.addressLine2
                            : ""
                        }
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        type="text"
                        label="Address line 2"
                        name="addressLine2"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </Grid>
                    <Grid xs={12} sm={12} md={2} paddingRight={{ md: 2 }}>
                      <TextField
                        value={formik.values.city}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        type="text"
                        label="City"
                        name="city"
                        required
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.city && Boolean(formik.errors.city)
                        }
                      />
                    </Grid>
                    <Grid xs={12} sm={12} md={2} paddingRight={{ md: 2 }}>
                      <TextField
                        value={formik.values.state}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        type="text"
                        label="State"
                        name="state"
                        required
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.state && Boolean(formik.errors.state)
                        }
                      />
                    </Grid>
                    <Grid xs={12} sm={12} md={2} paddingRight={{ md: 2 }}>
                      <TextField
                        value={formik.values.zip}
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        type="text"
                        label="Zip"
                        name="zip"
                        required
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.zip && Boolean(formik.errors.zip)}
                        helperText={
                          formik.touched.zip &&
                          (formik.errors.zip?.toString() as string)
                        }
                      />
                    </Grid>
                  </Grid>
                </div>
              )}
              {!updateForm ? (
                <TextField
                  value={`${formik.values?.phoneNumber}  ${
                    formik.values?.phoneNumberIsMobile
                      ? " is mobile number"
                      : "is not a mobile"
                  }`}
                  margin="normal"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  type="text"
                  label="Phone number"
                  name="phoneNumber"
                  disabled={!updateForm}
                />
              ) : (
                <div>
                  <Grid container>
                    <Grid xs={12} sm={12} md={4} paddingRight={{ md: 2 }}>
                      <TextField
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        type="text"
                        label="Phone number"
                        name="phoneNumber"
                        disabled={!updateForm}
                        required
                        defaultValue={
                          formik.values?.phoneNumber &&
                          formik.values?.phoneNumber
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.phoneNumber &&
                          Boolean(formik.errors.phoneNumber)
                        }
                      />
                    </Grid>
                    <Grid
                      xs={12}
                      sm={12}
                      md={2}
                      paddingRight={{ md: 2 }}
                      marginTop={{ md: 3 }}
                    >
                      <Radio
                        name="radio-buttons"
                        checked={
                          formik.values?.phoneNumberIsMobile ? true : false
                        }
                        onChange={(e) =>
                          formik.setFieldValue("phoneNumberIsMobile", true)
                        }
                      />
                      is Mobile
                    </Grid>

                    <Grid
                      xs={12}
                      sm={12}
                      md={3}
                      paddingRight={{ md: 2 }}
                      marginTop={{ md: 3 }}
                    >
                      <Radio
                        checked={
                          formik.values?.phoneNumberIsMobile ? false : true
                        }
                        name="radio-buttons"
                        onChange={(e) =>
                          formik.setFieldValue("phoneNumberIsMobile", false)
                        }
                      />
                      is not mobile
                    </Grid>
                  </Grid>
                </div>
              )}

              {formik.values?.alternativePhoneNumber && !updateForm && (
                <TextField
                  value={`${formik.values?.alternativePhoneNumber}  ${
                    formik.values?.alternativePhoneNumberIsMobile
                      ? " is mobile number"
                      : "is not a mobile"
                  }`}
                  margin="normal"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  type="text"
                  label="Phone number"
                  name="alternativePhoneNumber"
                  disabled={!updateForm}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.alternativePhoneNumber &&
                    Boolean(formik.errors.alternativePhoneNumber)
                  }
                />
              )}

              <TextField
                value={formik.values?.alternativeEmail}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="text"
                label="alternative Email"
                name="alternativeEmail"
                disabled={!updateForm}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.alternativeEmail &&
                  Boolean(formik.errors.alternativeEmail)
                }
              />

              {formik.values?.alternativePhoneNumber && updateForm && (
                <div>
                  <Grid container>
                    <Grid xs={12} sm={12} md={4} paddingRight={{ md: 2 }}>
                      <TextField
                        margin="normal"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        type="text"
                        label="Alternative phone number"
                        name="alternativePhoneNumber"
                        disabled={!updateForm}
                        defaultValue={
                          formik.values?.alternativePhoneNumber &&
                          formik.values?.alternativePhoneNumber
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.alternativePhoneNumber &&
                          Boolean(formik.errors.alternativePhoneNumber)
                        }
                      />
                    </Grid>
                    <Grid
                      xs={12}
                      sm={12}
                      md={2}
                      paddingRight={{ md: 2 }}
                      marginTop={{ md: 3 }}
                    >
                      <Radio
                        name="radio-buttons"
                        checked={
                          formik.values?.alternativePhoneNumberIsMobile
                            ? true
                            : false
                        }
                        onChange={(e) =>
                          formik.setFieldValue(
                            "alternativePhoneNumberIsMobile",
                            true
                          )
                        }
                      />
                      is Mobile
                    </Grid>

                    <Grid
                      xs={12}
                      sm={12}
                      md={3}
                      paddingRight={{ md: 2 }}
                      marginTop={{ md: 3 }}
                    >
                      <Radio
                        checked={
                          formik.values?.alternativePhoneNumberIsMobile
                            ? false
                            : true
                        }
                        name="radio-buttons"
                        onChange={(e) =>
                          formik.setFieldValue(
                            "alternativePhoneNumberIsMobile",
                            false
                          )
                        }
                      />
                      is not mobile
                    </Grid>
                  </Grid>
                </div>
              )}
              <TextField
                value={formik.values?.reference && formik.values?.reference}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="text"
                label="Reference"
                name="reference"
                disabled={!updateForm}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Box>
          ) : isLoading ? (
            <CircularProgress />
          ) : (
            "Oops! something went wrong"
          )}
        </Show>
      </form>
    </>
  );
};
