import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { NumericFormatCustom } from "components/inputs/custom-numeric-input";
import { useCreate } from "@refinedev/core";
import { useState } from "react";

interface ModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  fund: { fundName: string; fundId: number };
  refetch: any;
}

export const FeedModal: React.FC<ModalProps> = ({
  open,
  setOpen,
  fund,
  refetch,
}) => {
  const [amount, setAmount] = useState<{ value: number; error: boolean }>({
    value: 0,
    error: false,
  });
  const [description, setDescription] = useState<{ value: string; error: boolean }>({
    value: '',
    error: false,
  });
  const date = new Date().toISOString().substring(0, 10);
  const [executionDate, setExecutionDate] = useState({
    value: date,
    error: false,
  });

  const { mutate, isLoading } = useCreate();

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle color="primary">
        Make Adjustment to fund {fund.fundName}
      </DialogTitle>
      <Divider />
      <DialogContent>

        <Typography pt={1}>
          Please add the amount
        </Typography>
        <TextField
          value={amount.value || 0}
          error={amount.error}
          helperText={amount.error && "The amount is required!"}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">$</InputAdornment>
            ),
            inputComponent: NumericFormatCustom as any,
          }}
          onChange={(e) =>
            setAmount({ value: parseFloat(e.target.value), error: false })
          }
          label={"Amount"}
          name="amount"
        />
        <Typography pt={1}>
          Please add the description
        </Typography>
        <TextField
          value={description.value || ''}
          error={description.error}
          helperText={description.error && "The description is required!"}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          onChange={(e) =>
            setDescription({ value: e.target.value, error: false })
          }
          label={"Description"}
          name="description"
        />
        <Typography pt={2}>
          Please add the execution date
        </Typography>
        <Grid item xs={12}>
          <TextField
            id="referenceDate"
            error={executionDate.error}
            helperText={executionDate.error && "The date is required!"}
            defaultValue={executionDate.value}
            margin="normal"
            fullWidth
            InputLabelProps={{ shrink: true }}
            type="date"
            label="Date"
            name="executionDate"
            onChange={(event) => {
              setExecutionDate({ value: event.target.value, error: false });
            }}
          />
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          startIcon={
            isLoading && <CircularProgress size={20} color="inherit" />
          }
          variant="outlined"
          disabled={
            amount.error ||
            executionDate.error ||
            isLoading ||
            amount.value === 0 ||
            !amount.value
          }
          onClick={() => {
            if (amount.value === 0 || !amount.value || !executionDate.value) {
              if (amount.value === 0 || !amount.value) {
                setAmount({ value: amount.value, error: true });
              }
              if (!executionDate.value) {
                setExecutionDate({ ...executionDate, error: true });
              }
            } else {
              mutate(
                {
                  resource: "fund-adjustments",
                  values: {
                    adjustmentType: 'FEE',
                    amount: amount.value,
                    description: description,
                    fundAccountId: fund.fundId,
                    executionDate: executionDate.value,
                  },
                },
                {
                  onSuccess: () => {
                    setAmount({ value: 0, error: false });
                    setExecutionDate({ value: date, error: false });
                    refetch();
                    setOpen(false);
                  },
                }
              );
            }
          }}
        >
          Confirm
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            setAmount({ value: 0, error: false });
            setExecutionDate({ value: date, error: false });
            setOpen(false);
          }}
        >
          Cancel
        </Button>{" "}
      </DialogActions>
    </Dialog>
  );
};
