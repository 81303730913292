import React, { useEffect, useState } from "react";
import { List } from "@refinedev/mui";
import { IResourceComponentsProps, useCustom } from "@refinedev/core";
import { ICharity } from "interfaces/charity";
import PaginatedEntityList from "interfaces/PaginatedEntityList";
import {
  Box,
  CircularProgress,
  FilledInput,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import CharityCard from "components/card/CharityCard";
import SearchIcon from "@mui/icons-material/Search";
import { InView } from "react-intersection-observer";
import ClearIcon from "@mui/icons-material/Clear";

export const CharitiesList: React.FC<IResourceComponentsProps> = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [search, setSearch] = useState("");
  const [offset, setOffset] = useState(0);
  const [charitiesData, setCharitiesData] = useState<ICharity[]>([]);
  const [limit] = useState(12);
  const {
    data: charities,
    isSuccess: isGetCharitiesSuccess,
    isFetching: isGetCharitiesLoading,
    refetch,
  } = useCustom({
    url: `charities`,
    method: "get",
    config: {
      query: {
        searchTerm: searchTerm,
        limit: limit,
        offset: offset,
        "orderBy[updatedAt]": "DESC",
      },
    },
  });

  useEffect(() => {
    if (charities) {
      if (
        (charities.data as PaginatedEntityList<ICharity>)?.pagination
          ?.offset === 0
      ) {
        setCharitiesData(
          (charities.data as PaginatedEntityList<ICharity>).data
        );
      } else {
        setCharitiesData([
          ...charitiesData,
          ...(charities.data as PaginatedEntityList<ICharity>).data,
        ]);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [charities]);

  const searchByKeyword = (event: any) => {
    if (event.key === "Enter") {
      clear();
      setSearchTerm(event.target.value);
    }
  };
  const reset = () => {
    setSearch("");
    setSearchTerm("");
    clear();
  };
  const refresh = () => {
    clear();
    refetch();
  };
  const clear = () => {
    setCharitiesData([]);
    setOffset(0);
  };
  const fetchData = (inView: boolean) => {
    if (inView && charities?.data?.pagination.nextOffset)
      setOffset(charities?.data?.pagination.nextOffset);
  };


  return (
    <>
      <List
        wrapperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            padding: 0,
          },
        }}
        title="Charities List"
      >
        <Grid container spacing={2} justifyContent={"space-between"}>
          <Grid item xs={12} sm={6} sx={{ textAlign: "start" }}>
            <FormControl variant="outlined" fullWidth>
              <FilledInput
                className="search"
                onKeyDown={(event) => {
                  searchByKeyword(event);
                }}
                onChange={(event) => {
                  setSearch(event.target.value);
                }}
                value={search}
                placeholder="Type keyword or name"
                size="small"
                id="outlined-adornment-weight"
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
                endAdornment={
                  search !== "" && (
                    <InputAdornment position="end">
                      <IconButton onClick={reset} edge="end">
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }
              />
            </FormControl>
          </Grid>
        </Grid>

        <Box>
          <Grid sx={{ mt: 2 }} container spacing={2}>
            {isGetCharitiesSuccess && charitiesData.length > 0
              ? charitiesData.map((charity) => (
                <Grid key={charity.ein} item xs={12} md={6}>
                  <CharityCard charity={charity} refresh={refresh} />
                </Grid>
              ))
              : !isGetCharitiesLoading && (
                <Typography
                  sx={{
                    color: "neutral.main",
                    pl: 3,
                  }}
                  variant="smallCopyBold"
                >
                  No charity found.
                </Typography>
              )}
            {isGetCharitiesLoading ? (
              <Grid item xs={12} sm={10} textAlign={"center"} sx={{ m: 3 }}>
                {" "}
                <CircularProgress color="primary" />
              </Grid>
            ) : charities?.data?.pagination.hasNext ? (
              <InView
                as="div"
                onChange={(inView) => fetchData(inView)}
              ></InView>
            ) : null}
          </Grid>
        </Box>
      </List>
    </>
  );
};
