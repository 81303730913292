import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  listItemClasses,
} from "@mui/material";
import { warm, neutral } from "style/color";
import { shortFormat } from "utils/date-format";
import { useCustom } from "@refinedev/core";
import { IUser } from "interfaces/user";
import { useNavigate } from "react-router-dom";

interface UsersListProps {}

export const UsersList: React.FC<UsersListProps> = () => {
  const {
    data: usersData,
    isSuccess,
    isFetching: isLoading,
  } = useCustom({
    url: `users/status/PENDING`,
    method: "get",
  });

  const [users, setUsers] = useState<IUser[]>([]);
  const navigate = useNavigate();
  useEffect(() => {
    if (usersData && usersData.data.length > 0) {
      setUsers(usersData.data as IUser[]);
    }
  }, [usersData]);

  return (
    <>
      <Box marginTop={4}>
        {isLoading ? (
          <Grid xs={12} sm={12} textAlign={"center"} sx={{ m: 3 }}>
            {" "}
            <CircularProgress color="primary" />
          </Grid>
        ) : (
          <Grid container xs={12}>
            <List
              className="successor"
              sx={{
                width: "100%",
                bgcolor: "background.paper",

                [` & .${listItemClasses.root}:hover`]: {
                  backgroundColor: warm[200],
                  cursor: "pointer",
                },
              }}
            >
              {isSuccess &&
                users &&
                users.length > 0 &&
                users.map((user: IUser, index: number) => {
                  return (
                    <>
                      <ListItem
                        key={index}
                        onClick={() => navigate(`/users/status/${user.sub}`)}
                      >
                        <Grid
                          alignItems="center"
                          container
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            px: 2,
                          }}
                        >
                          <Grid item xs={12} sm={3} textAlign={"start"}>
                            <ListItemText
                              sx={{
                                display: "inline-grid",
                                width: "auto",
                                flex: "none",
                              }}
                              primary={
                                <Typography
                                  sx={{ color: `neutral.main` }}
                                  variant="bodyCopyBold"
                                  gutterBottom
                                >
                                  {`${user.firstName} ${user.lastName}`}
                                </Typography>
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={2} textAlign={"start"}>
                            <Typography
                              variant="bodyCopyRegular"
                              sx={{
                                color: neutral[900],
                                textTransform: "capitalize",
                              }}
                            >
                              {user.email}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={2} textAlign={"start"}>
                            <Typography
                              variant="bodyCopyRegular"
                              sx={{ color: neutral[600] }}
                            >
                              {shortFormat(new Date(user.dateOfBirth))}
                            </Typography>
                          </Grid>
                        </Grid>
                      </ListItem>

                      <Divider key={index} />
                    </>
                  );
                })}
            </List>
          </Grid>
        )}
        {!isLoading && users.length === 0 && (
          <Typography variant="subtitle3">No pending requests.</Typography>
        )}
      </Box>
    </>
  );
};
