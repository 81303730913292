import { Divider } from "@aws-amplify/ui-react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { useCreate } from "@refinedev/core";
import { useState } from "react";
import { neutral } from "style/color";
import { isValidEmail } from "utils/valid-email";
interface CreateAdminModalProps {
  open: boolean;
  refetch: any;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
export const CreateAdmin: React.FC<CreateAdminModalProps> = ({
  setOpen,
  refetch,
  open,
}) => {
  const { mutate, isLoading } = useCreate();

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          setEmail("");
          setEmailError(false);
        }}
        fullWidth
      >
        <DialogTitle>Create new Admin </DialogTitle>
        <Divider />
        <DialogContent>
          <TextField
            error={emailError}
            helperText={
              emailError &&
              "Email is required and must follow universal email structure *****@*****.***"
            }
            margin="normal"
            fullWidth
            style={{ marginTop: 10 }}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
              setEmail(e.target.value);
              e.target.value === ""
                ? setEmailError(true)
                : setEmailError(false);
            }}
            type="text"
            name="email"
            label="Admin email"
          />
          <Typography
            sx={{
              color: neutral[700],
              paddingLeft: 1,
            }}
            variant="smallCopyBold"
            paragraph
          >
            *The new admin will receive an email with temporary password.{" "}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={isLoading || email === ""}
            startIcon={
              isLoading && <CircularProgress size={20} color="inherit" />
            }
            onClick={() => {
              if (!isValidEmail(email)) {
                setEmailError(true);
              } else {
                mutate(
                  {
                    resource: `platform-admin`,
                    values: { email: email },
                    successNotification: () => {
                      return {
                        message: "Admin was added successfully",
                        type: "success",
                      };
                    },
                    errorNotification: (error) => {
                      return {
                        message: error?.message as string,
                        description: "Error",
                        type: "error",
                      };
                    },
                  },
                  {
                    onSuccess: () => {
                      setEmail("");
                      setEmailError(false);
                      setOpen(false);

                      refetch();
                    },
                  }
                );
              }
            }}
          >
            Confirm
          </Button>
          <Button
            onClick={() => {
              setOpen(false);
              setEmail("");
              setEmailError(false);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
